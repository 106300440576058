.App {
  text-align: center;
  margin: 50px;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}
